import { TEventTypes } from '../../generated/graphql';

export const translateEventType = (type: TEventTypes) => {
  switch (type) {
    case TEventTypes.Birthday:
      return 'Birthday';
    case TEventTypes.WorkAnniversary:
      return 'Work Anniversary';
    case TEventTypes.NewHire:
      return 'New Hire';
    case TEventTypes.BabyGift:
      return 'Baby Gift';
    case TEventTypes.Wedding:
      return 'Wedding/Engagement';
    case TEventTypes.ReplacementWorkAnniversary:
      return 'Replacement Work Anniversary';
    case TEventTypes.ReplacementEmployeeBirthday:
      return 'Replacement Employee Birthday';
    case TEventTypes.CarePackage:
      return 'Care Package';
    case TEventTypes.CustomBricks:
      return 'Custom Bricks';
    case TEventTypes.Other:
      return 'Other';
  }
};

export const ONE_OFF_EVENT_TYPES = {
  babyGift: 'Baby Gift',
  wedding: 'Wedding/Engagement',
  replacementWorkAnniversary: 'Replacement Work Anniversary',
  replacementEmployeeBirthday: 'Replacement Employee Birthday',
  carePackage: 'Care Package',
  customBricks: 'Custom Bricks',
  other: 'Other',
};
