import { useQueryAll } from '../shared/useQueryAll';
import {
  TBasicLogFieldsFragment,
  TListLogsQuery,
  TListLogsQueryVariables,
  TLogUserTypes,
  useGetAdminUserMutation,
  useListLogsLazyQuery,
} from '../../generated/graphql';
import { useEffect, useMemo, useState } from 'react';
import { useAllCompaniesMap } from '../companies/queries';
import { useAllEmployeesMap } from '../employees/queries';
import _ from 'lodash';
import { splitLogFieldName } from './shared';

export const useFullLogs = <T extends TBasicLogFieldsFragment>(logs: T[]) => {
  const { data: companiesMap, error: companiesError, loading: companiesLoading } = useAllCompaniesMap();
  const { data: employeesMap, error: employeesError, loading: employeesLoading } = useAllEmployeesMap();
  //const { data: dependantsMap, error: dependantsError, loading: dependantsLoading } = useAllDependantsMap();
  //const { data: addressesMap, error: addressesError, loading: addressesLoading } = useAllAddressesMap();
  const fullLogs = useMemo(() => {
    return logs.map((e) => {
      const response = JSON.parse(e.response ?? '{}');
      const getEmployee = () => {
        if (response?.event && response?.event?.employeeId) return employeesMap[response?.event?.employeeId];
        if (response?.gift && response?.gift?.employeeId) return employeesMap[response?.gift?.employeeId];
        if (response?.employeeId) return employeesMap[response?.employeeId];
        return response;
      };
      return {
        ...e,
        company: e.companyId ? companiesMap[e.companyId] : undefined,
        employee: getEmployee(),
        identity: JSON.parse(e.identity ?? '{}'),
        response,
        variables: JSON.parse(e.variables ?? '{}'),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesMap, JSON.stringify(logs)]);
  return {
    data: fullLogs,
    error: companiesError || employeesError,
    loading: companiesLoading || employeesLoading,
  };
};

export type TLogType<T extends TBasicLogFieldsFragment = TBasicLogFieldsFragment> = ReturnType<
  typeof useFullLogs<T>
>['data'][number];

export type TLogFullType = TLogType & {
  identityUserName?: string;
  identityUserEmail?: string;
  action?: string;
  entity?: string;
};

const getUserIdentity = async (log: TLogType, getAdminUserFn: any) => {
  let identityUserName = '-',
    identityUserEmail = '-';
  if (log.userType === TLogUserTypes.Client) {
    identityUserName = log.identity?.resolverContext?.name as string;
    identityUserEmail = log.identity?.resolverContext?.email as string;
    return { identityUserName, identityUserEmail };
  }
  if (log.userType === TLogUserTypes.Internal) {
    const userId = log?.identity?.username;
    const userPoolMatch = log?.identity?.issuer?.match(/[^/]+$/);
    const userPoolId = userPoolMatch ? userPoolMatch[0] : '0';
    try {
      const getAdminData = await getAdminUserFn({
        variables: { userId, userPoolId },
      });
      const adminIdentity = JSON.parse(getAdminData?.data?.getAdminUser ?? '{}');
      adminIdentity?.UserAttributes?.forEach((attr: any) => {
        if (attr?.Name === 'name') identityUserName = attr?.Value;
        if (attr?.Name === 'email') identityUserEmail = attr?.Value;
      });
      return { identityUserName, identityUserEmail };
    } catch (e) {
      console.log('getUserIdentity ERROR', e);
      return { identityUserName, identityUserEmail };
    }
  }
};

export const useQueryAllLogs = (companyId: string) => {
  const [logs, setLogs] = useState<TLogFullType[]>([]);
  const [getAdminUser] = useGetAdminUserMutation();
  const { data, error, loading } = useQueryAll<TListLogsQuery, TListLogsQueryVariables>({
    useQuery: useListLogsLazyQuery,
    variables: {
      companyId,
    },
  });
  const { data: fullLogs, loading: fullLoading, error: fullError } = useFullLogs(_.compact(data?.listLogs.items));

  useEffect(() => {
    if (!fullLogs.length) return;
    (async () => {
      setLogs(
        _.orderBy(
          (
            await Promise.all(
              fullLogs.map(async (log: TLogType) => {
                const splitFieldName = splitLogFieldName(log?.fieldName as string);
                return {
                  ...log,
                  action: splitFieldName[0],
                  entity: splitFieldName[1],
                  ...(await getUserIdentity(log, getAdminUser)),
                };
              }),
            )
          ).filter((log: TLogType) => log?.companyId),
          ['ocurredAt'],
          ['desc'],
        ),
      );
    })();
  }, [fullLogs, getAdminUser]);

  return {
    data: logs,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};
