import { TMaterialIcons } from '@chocolate-soup-inc/cs-frontend-components';
import { TAddressParentTypes, TDeliveryMethod, TGiftFieldsFragment } from '../../generated/graphql';
import { TGiftType } from '../gifts/queries';

export const getReadableAddressParentType = (addressType?: TAddressParentTypes) => {
  if (addressType == null) return undefined;

  switch (addressType) {
    case TAddressParentTypes.Company:
    case TAddressParentTypes.Office:
      return 'Office';
    case TAddressParentTypes.Employee:
      return 'Home';
    default:
      return undefined;
  }
};

export const getReadableDeliveryMethodType = (gift: TGiftType<TGiftFieldsFragment>) => {
  switch (gift?.company?.deliveryMethod) {
    case TDeliveryMethod.Home:
      return 'Home';
    case TDeliveryMethod.Office:
      return gift?.employee?.office ? gift?.employee?.office?.name : 'Office';
    case TDeliveryMethod.Hybrid:
      return gift?.employee?.office ? gift?.employee?.office?.name : 'Home';
    case TDeliveryMethod.Default:
    default:
      return undefined;
  }
};

export const getReadableAddressProperty = (gift: any, deliveryMethod: string | undefined, key: string) => {
  if (deliveryMethod === null || key === null) return undefined;

  const getDisplayAddress = (address: any) => {
    if (!address) return 'Not found.';
    return address.useGoogleAddress ? address?.addressFromGoogle[key] : address[key];
  };

  switch (deliveryMethod) {
    case TDeliveryMethod.Home:
      return getDisplayAddress(gift?.employee?.address);
    case TDeliveryMethod.Office:
      return gift?.employee?.office
        ? getDisplayAddress(gift?.employee?.office?.address)
        : getDisplayAddress(gift?.company?.address);
    case TDeliveryMethod.Hybrid:
      return gift?.employee?.office
        ? getDisplayAddress(gift?.employee?.office?.address)
        : getDisplayAddress(gift?.employee?.address);
    case TDeliveryMethod.Default:
    default:
      return undefined;
  }
};

export const getIconFromAddressParentType = (addressType?: TAddressParentTypes): TMaterialIcons | undefined => {
  const readableType = getReadableAddressParentType(addressType);
  if (readableType == null) return undefined;

  if (readableType === 'Home') {
    return 'home';
  } else {
    return 'domain';
  }
};
