import { useMemo } from 'react';
import {
  OnCompanyUserChangedDocument,
  TListUsersIncludingCsUsersQuery,
  TListUsersIncludingCsUsersQueryVariables,
  TOnCompanyUserChangedSubscriptionVariables,
  TUserFieldsFragment,
  useListUsersIncludingCsUsersLazyQuery,
} from '../../generated/graphql';
import { useAllCompaniesMap } from '../companies/queries';
import { useQueryAll } from '../shared/useQueryAll';
import _ from 'lodash';

// LIST

export const useFullUsers = <T extends TUserFieldsFragment>(users: T[]) => {
  const { data: companiesMap, error: companiesError, loading: companiesLoading } = useAllCompaniesMap();

  const fullUsers = useMemo(() => {
    return users.map((u) => {
      return {
        ...u,
        company: u.companyId ? companiesMap[u.companyId] : undefined,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesMap, JSON.stringify(users)]);

  return {
    data: fullUsers,
    error: companiesError,
    loading: companiesLoading,
  };
};

export type TUserType<T extends TUserFieldsFragment = TUserFieldsFragment> = ReturnType<
  typeof useFullUsers<T>
>['data'][number];

export const useQueryAllUsers = (variables: TListUsersIncludingCsUsersQueryVariables) => {
  const { data, error, loading } = useQueryAll<
    TListUsersIncludingCsUsersQuery,
    TListUsersIncludingCsUsersQueryVariables,
    TOnCompanyUserChangedSubscriptionVariables
  >({
    subscriptionDoc: OnCompanyUserChangedDocument,
    subscriptionVariables: {
      companyId: variables.companyId,
    },
    useQuery: useListUsersIncludingCsUsersLazyQuery,
    variables,
  });

  const {
    data: users,
    error: fullError,
    loading: fullLoading,
  } = useFullUsers(_.compact(data?.listUsersIncludingCSUsers?.items));

  return {
    data: users,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};
