import {
  ErrorPage,
  Icon,
  IconButton,
  Link,
  LoadingPage,
  Modal,
  TableInner,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import { TablePage } from '../../../components/tablePage/TablePage';
import { useCallback, useEffect, useMemo, useState } from 'react';
import format from 'date-fns/format';
import { Storage } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { serializeError } from 'serialize-error';
import { toast } from 'react-toastify';
import { Filters, TFiltersProps } from '../../../components/filters/Filters';
import _ from 'lodash';

import styles from './EmployeesImportList.module.scss';
import { TRosterFileType, useQueryAllRosterFiles } from '../../../entities/rosterFiles/queries';
import { useFilter } from '../../../contexts/filters';
import { ReviewStep } from '../EmployeesImport/steps/ReviewStep';
import clsx from 'clsx';
import tableStyles from '../../../components/tablePage/TablePage.module.scss';

type TChangelogModalInfo = Partial<{
  changelog: string | null;
  createdAt: string | null;
  mapping: string;
  companyId: string;
  companyName: string | null;
}>;

export const EmployeesImportList = () => {
  const { filtersPageMode } = useFilter();
  useEffect(() => {
    filtersPageMode();
  }, []); //eslint-disable-line

  const { data: rosterFiles, error, loading } = useQueryAllRosterFiles();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [changelogModalInfo, setChangelogModalInfo] = useState<TChangelogModalInfo>({});
  const [companyFilter, setCompanyFilter] = useState<string>();

  const companies = useMemo(() => {
    return _.uniqBy(_.compact(rosterFiles.map((r) => r.company)), 'id');
  }, [rosterFiles]);

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [];

    filtersList.push({
      disabled: companies.length === 0,
      includeEmptyOption: true,
      label: 'Company',
      name: 'companyId',
      onChange: (v) => {
        if (v == null) setCompanyFilter(undefined);
        else setCompanyFilter(v as string);
      },
      options: companies.map((c) => ({ label: c.name, value: c.id })),
      type: 'singleSelect',
      value: companyFilter,
    });

    return filtersList;
  }, [companies, companyFilter]);

  const filteredRosterFiles = useMemo(() => {
    return rosterFiles
      .filter((r) => {
        if (companyFilter != null && r.companyId !== companyFilter) {
          return false;
        }

        return true;
      })
      .sort((a, b) => {
        return new Date(b.createdAt as string).getTime() - new Date(a.createdAt as string).getTime();
      });
  }, [companyFilter, rosterFiles]);

  const getRowId = useCallback((rosterFile: TRosterFileType) => {
    return rosterFile.id;
  }, []);

  const changelogInfo = useCallback((cell: TRosterFileType) => {
    if (!cell.changelog && !cell.changelogFile) return;
    const { changelog, changelogFile, createdAt, mapping, companyId } = cell;
    if (changelog) {
      setIsModalOpen(true);
      setChangelogModalInfo({
        changelog,
        createdAt,
        mapping,
        companyId,
        companyName: cell.company?.name,
      });
      return;
    }

    const { key, bucket, region } = changelogFile ?? {};
    if (!key || !bucket || !region) return;
    Storage.get(key, {
      bucket,
      region,
    })
      .then((signedURL) => {
        fetch(signedURL).then((response) => {
          response.text().then((text) => {
            setIsModalOpen(true);
            setChangelogModalInfo({
              changelog: text,
              createdAt,
              mapping,
              companyId,
              companyName: cell.company?.name,
            });
          });
        });
      })
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error opening the file.');
      });
  }, []);

  if (error) return <ErrorPage error={error} />;
  if (loading) return <LoadingPage />;

  return (
    <TablePage dataLoading={loading} error={error} title='Import History'>
      <Filters filters={filters} />
      <TableInner<TRosterFileType>
        data={filteredRosterFiles}
        emptyText={rosterFiles.length === 0 ? 'No import files uploaded.' : 'No import files for the current filters.'}
        expandable={false}
        fillSelectedRows={false}
        fixedHeader={true}
        getRowId={getRowId}
        hideSubRowsSelect={true}
        hoverableRows={false}
        selectableRows={false}
        virtual={true}
        columns={[
          {
            header: 'Company',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpacePrimary)}> {cell.row.original.company?.name || '-'}</div>
              );
            },
          },
          {
            header: 'File Name',
            cell: ({ cell }) => {
              const { bucket, key, region } = cell.row.original.file;
              return (
                <div className={clsx(tableStyles.tableSpacePrimary)}>
                  {key ? (
                    <Link
                      label={key.split('/').pop() as string}
                      onClick={() => {
                        Storage.get(key, {
                          bucket,
                          region,
                        })
                          .then((signedURL) => {
                            open(signedURL, '_blank');
                          })
                          .catch((error) => {
                            console.error(serializeError(error));
                            toast.error('There was an error opening the file.');
                          });
                      }}
                    />
                  ) : (
                    '-'
                  )}
                </div>
              );
            },
          },
          {
            header: 'Import Start At',
            cell: ({ cell }) => {
              const { importStartedAt } = cell.row.original;
              return (
                <div className={clsx(tableStyles.tableSpaceSecondary)}>
                  {importStartedAt ? format(new Date(importStartedAt), 'do MMM, yyyy hh:mmaaa') : '-'}
                </div>
              );
            },
          },
          {
            header: 'Import Ended At',
            cell: ({ cell }) => {
              const { impoortEndedAt } = cell.row.original;
              return (
                <div className={clsx(tableStyles.tableSpaceSecondary)}>
                  {impoortEndedAt ? format(new Date(impoortEndedAt), 'do MMM, yyyy hh:mmaaa') : '-'}
                </div>
              );
            },
          },
          {
            header: 'Errors',
            cell: ({ cell }) => {
              const { bucket, key } = JSON.parse(cell.row.original.importErrors ?? '{}');
              return (
                <div className={clsx(tableStyles.tableSpaceQuaternary)}>
                  {!cell.row.original.importErrors ? (
                    '-'
                  ) : bucket && key ? (
                    <Link
                      label={'Errors file'}
                      onClick={() => {
                        Storage.get(key, {
                          bucket,
                          region: 'ca-central-1',
                        })
                          .then((signedURL) => {
                            open(signedURL, '_blank');
                          })
                          .catch((error) => {
                            console.error(serializeError(error));
                            toast.error('There was an error opening the file.');
                          });
                      }}
                    />
                  ) : (
                    "Couldn't parse errors."
                  )}
                </div>
              );
            },
          },
          {
            header: 'Status',
            cell: ({ cell }) => {
              const { importStartedAt, impoortEndedAt, importErrors } = cell.row.original;
              const thisImportErrors = JSON.parse(typeof importErrors === 'string' ? importErrors : '{}');
              const StatusTooltip = () => {
                if (!importStartedAt)
                  // PENDING
                  return (
                    <Tooltip message='The import will start soon.'>
                      <Icon className={styles.pending} icon='pending' />
                    </Tooltip>
                  );
                if (!impoortEndedAt)
                  // RUNNING
                  return (
                    <Tooltip message='Import running.'>
                      <Icon className={styles.running} icon='cached' />
                    </Tooltip>
                  );
                if (!_.isEmpty(thisImportErrors) && importErrors)
                  // ERROR
                  return (
                    <Tooltip message='Import finished with errors.'>
                      <Icon className={styles.error} icon='error' />
                    </Tooltip>
                  );
                // SUCCEEDED
                return (
                  <Tooltip message='Import finished successfully.'>
                    <Icon className={styles.success} icon='check_circle' />
                  </Tooltip>
                );
              };

              return (
                <div className={clsx(tableStyles.tableSpaceQuaternary)}>
                  <StatusTooltip />
                </div>
              );
            },
          },
          {
            header: 'Changelog',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceQuaternary)}>
                  <Tooltip
                    message={
                      cell.row.original.changelog || cell.row.original.changelogFile
                        ? 'View history log of this import.'
                        : 'History log not found.'
                    }
                  >
                    <IconButton
                      disabled={!cell.row.original.changelog && !cell.row.original.changelogFile}
                      className={clsx(styles.success, styles.sm)}
                      icon='history'
                      onClick={() => {
                        changelogInfo(cell.row.original);
                      }}
                    />
                  </Tooltip>
                </div>
              );
            },
          },
        ]}
      />
      <Modal
        headline={`Import changes of ${changelogModalInfo.companyName ?? '{not found}'} at ${format(
          new Date(changelogModalInfo.createdAt ?? '0'),
          'do MMM, yyyy hh:mmaaa',
        )}`}
        open={isModalOpen}
        size='large'
        closeModal={() => {
          setIsModalOpen(false);
        }}
      >
        <ReviewStep
          companyId={changelogModalInfo.companyId ?? '0'}
          mapping={JSON.parse(changelogModalInfo.mapping ?? '{}')}
          changelog={JSON.parse(changelogModalInfo.changelog ?? '{}')}
        />
      </Modal>
    </TablePage>
  );
};
